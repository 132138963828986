import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Icon, IconList } from "@ryerson/frontend.assets";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { navigate } from "gatsby";
import styled from "@emotion/styled";

export type IndustryComponentPartPiece = {
	title: string;
	icon: keyof IconList;
	url: string;
};

export type IndustryComponentPart = {
	title: string;
	pieces: IndustryComponentPartPiece[];
};

export type IndustryComponent = {
	title: string;
	helpText: string[];
	componentParts: IndustryComponentPart[];
};

export type IndustryContent = {
	title: string;
	description: string;
	bannerImageUrl: string;
	industryComponents: IndustryComponent[];
};

export type TurretIndustriesContent = {
	industries: IndustryContent[];
	title: string;
	description: string;
};

export type TurretIndustriesProps = {
	content: TurretIndustriesContent;
};

const HeaderSection = styled.div`
	display: inline-block;
	width: 50%;
	vertical-align: bottom;
	height: auto;
	&:nth-child(2) {
		padding-left: 80px;
		box-sizing: border-box;
	}
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 10px;
`;

const LargeSpacer = styled.div`
	display: block;
	width: 100%;
	height: 50px;
`;

const IndustryNav = styled.div`
	display: block;
	width: 100%;
	height: 36px;
	box-sizing: border-box;
`;

const IndustryNavItem = styled.div`
	display: inline-block;
	vertical-align: top;
	padding-bottom: 15px;
	height: 36px;
	box-sizing: border-box;
	cursor: pointer;
	margin-right: 40px;
	&:last-of-type {
		margin-right: 0px;
	}
`;

const Banner = styled.div`
	width: 100%;
	height: 210px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: relative;
`;

const BannerOverlay = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.3);
`;

const MainContent = styled.div`
	width: 100%;
	height: auto;
	transition: opacity 0.2s ease;
`;

const ComponentContainer = styled.div`
	width: 100%;
	display: block;
	height: auto;
	position: relative;
`;

const ComponentPart = styled.div`
	display: inline-block;
	width: 260px;
	vertical-align: top;
	margin-right: 10px;
	&:last-of-type {
		margin-right: 0px;
	}
	height: auto;
`;

const ComponentPiece = styled.div`
	display: block;
	width: 100%;
	height: 80px;
	box-sizing: border-box;
	padding-right: 80px;
	padding-left: 30px;
	position: relative;
`;

const ComponentPieceTitle = styled(Flex)`
	width: 100%;
	height: 100%;
`;

const ComponentPieceIcon = styled.div`
	display: block;
	position: absolute;
	right: 27px;
	top: 25px;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	img {
		display: inline-block;
	}
`;

const ButtonLinkContainer = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
	height: auto;
`;

const FauxButtonLink = styled.div`
	display: block;
	white-space: nowrap;
	width: auto;
	height: auto;
	cursor: pointer;
`;

const FauxButtonLinkText = styled.div`
	display: inline-block;
	width: auto;
	height: auto;
	vertical-align: middle;
	text-align: right;
	margin-right: 15px;
`;

const FauxButtonLinkIcon = styled.div`
	display: inline-block;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	vertical-align: middle;
	line-height: 44px;
	text-align: center;
`;

const Divider = styled.hr`
	width: 100%;
	display: block;
	opacity: 0.1;
	margin-top: 30px;
	margin-bottom: 0px;
`;

const MobileDivider = styled.hr`
	width: 100%;
	display: block;
	opacity: 0.1;
	margin-top: 0px;
	margin-bottom: 0px;
	margin-bottom: 24px;
`;

const MobileIndustryActionButton = styled.div`
	display: block;
	position: absolute;
	top: 50%;
	height: 24px;
	width: 24px;
	text-align: center;
	border-radius: 24px;
	line-height: 25px;
	margin-top: -12px;
	cursor: pointer;
	left: -12px;
`;

const MobileIndustry = styled.div`
	display: block;
	width: 100%;
	padding: 20px 0px;
	bix-sizing: border-box;
`;

const MobileIndustryLeft = styled.div`
	display: inline-block;
	vertical-align: top;
	width: calc(55% - 14px);
	margin-right: 28px;
`;

const MobileIndustryRight = styled.div`
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: calc(45% - 14px);
	height: 170px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: auto 100%;
`;

const IndustriesWeServe: React.FC<TurretIndustriesProps> = ({ content }) => {
	const { theme } = useTheme();
	const [currentIndustry, setCurrentIndustry] = React.useState<IndustryContent>(
		content.industries[0]
	);
	const [transitioning, setTransitioning] = React.useState<boolean>(false);
	const [mobileIndustry, setMobileIndustry] = React.useState<IndustryContent>(
		content.industries[0]
	);
	const [showMobileIndustryDetails, setShowMobileIndustryDetails] =
		React.useState<boolean>(false);
	const [bodyStyle, setBodyStyle] = React.useState<any>("");
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="primary" vPadding="105px">
					<HeaderSection>
						<Typography
							css={css`
								margin-top: 0px;
								margin-bottom: 0px;
							`}
							type="primary"
							variant="h1"
						>
							{content.title}
						</Typography>
					</HeaderSection>
					<HeaderSection>
						<Typography variant="div" type="primary" size="sm">
							{content.description}
						</Typography>
					</HeaderSection>
					<LargeSpacer />
					<Spacer />
					<Spacer />
					<IndustryNav
						css={css`
							border-bottom: 1px solid ${theme.colors.primary.lighterGray};
						`}
					>
						{content.industries.map((industry: IndustryContent, index: number) => {
							return (
								<IndustryNavItem
									key={index}
									css={css`
										transition: border-bottom 0.2s ease;
										border-bottom: 1px solid
											${industry.title === currentIndustry.title
												? theme.colors.primary.primaryBrand
												: theme.colors.primary.lighterGray};
									`}
									onClick={() => {
										setTransitioning(true);
										setTimeout(() => {
											setCurrentIndustry(industry);
											setTransitioning(false);
										}, 200);
									}}
								>
									<Typography
										type="primary"
										color={theme.colors.primary.header}
										weight="bold"
										size="md"
										variant="div"
									>
										{industry.title}
									</Typography>
								</IndustryNavItem>
							);
						})}
					</IndustryNav>
					<Spacer />
					<Spacer />
					<Spacer />
					<MainContent
						css={css`
							opacity: ${transitioning ? "0" : "1"};
						`}
					>
						<Banner
							css={css`
								background-image: url(${currentIndustry.bannerImageUrl});
							`}
						>
							<BannerOverlay>
								<Flex
									justifyContent="flex-end"
									direction="column"
									css={css`
										padding: 30px;
										max-width: 650px;
										height: 100%;
									`}
								>
									<FlexItem>
										<Typography
											variant="h2"
											type="tertiary"
											css={css`
												margin-top: 0px;
												margin-bottom: 24px;
											`}
										>
											{currentIndustry.title}
										</Typography>
									</FlexItem>
									<FlexItem>
										<Typography
											variant="div"
											color={theme.colors.tertiary.header}
											size="sm"
										>
											{currentIndustry.description}
										</Typography>
									</FlexItem>
								</Flex>
							</BannerOverlay>
						</Banner>
						{currentIndustry.industryComponents.map(
							(component: IndustryComponent, index: number) => {
								return (
									<ComponentContainer key={index}>
										<Spacer />
										<Spacer />
										<Typography
											css={css`
												height: 40px;
												line-height: 40px;
												margin-bottom: 20px;
												position: relative;
											`}
											variant="div"
											size="lg"
											color={theme.colors.primary.header}
										>
											{component.title}
											<ButtonLinkContainer>
												<FauxButtonLink
													onClick={() => {
														if (
															window &&
															(window as any).LiveChatWidget
														) {
															(window as any).LiveChatWidget.call(
																"maximize"
															);
														}
													}}
												>
													<FauxButtonLinkText>
														{component.helpText.map(
															(text: string, index: number) => {
																return (
																	<Typography
																		key={index}
																		variant="div"
																		size="sm"
																		weight="bold"
																		color={
																			theme.colors.primary
																				.header
																		}
																	>
																		{text}
																	</Typography>
																);
															}
														)}
													</FauxButtonLinkText>
													<FauxButtonLinkIcon
														css={css`
															background-color: ${theme.colors.primary
																.link};
														`}
													>
														<Icon
															css={css`
																cursor: pointer;
															`}
															icon="question-mark"
															color={theme.colors.primary.white}
															size="sm"
														/>
													</FauxButtonLinkIcon>
												</FauxButtonLink>
											</ButtonLinkContainer>
										</Typography>

										{component.componentParts.map(
											(part: IndustryComponentPart, index: number) => {
												return (
													<ComponentPart key={index}>
														<ComponentPiece
															css={css`
																background-color: ${theme.colors
																	.primary.darkGray};
															`}
														>
															<ComponentPieceTitle
																direction="column"
																justifyContent="space-around"
															>
																<FlexItem>
																	<Typography
																		variant="span"
																		size="sm"
																		color={
																			theme.colors.primary
																				.white
																		}
																	>
																		{part.title}
																	</Typography>
																</FlexItem>
															</ComponentPieceTitle>
														</ComponentPiece>
														{part.pieces.map(
															(
																piece: IndustryComponentPartPiece,
																index: number
															) => {
																return (
																	<ComponentPiece
																		key={index}
																		css={css`
																			background-color: ${theme
																				.colors.secondary
																				.background};
																		`}
																	>
																		<ComponentPieceTitle
																			direction="column"
																			justifyContent="space-around"
																			onClick={() => {
																				navigate(piece.url);
																			}}
																		>
																			<FlexItem>
																				<Typography
																					variant="span"
																					size="sm"
																					color={
																						theme.colors
																							.primary
																							.header
																					}
																				>
																					{piece.title}
																				</Typography>
																			</FlexItem>
																		</ComponentPieceTitle>
																		<ComponentPieceIcon>
																			<Icon
																				icon={piece.icon}
																				size="lg"
																				color={
																					theme.colors
																						.primary
																						.gray
																				}
																			/>
																		</ComponentPieceIcon>
																	</ComponentPiece>
																);
															}
														)}
													</ComponentPart>
												);
											}
										)}
										{index === currentIndustry.industryComponents.length - 1 ? (
											<></>
										) : (
											<Divider
												css={css`
													color: ${theme.colors.primary.darkerGray};
												`}
											/>
										)}
									</ComponentContainer>
								);
							}
						)}
					</MainContent>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<Spacer />
				<Spacer />
				<Typography
					variant="div"
					css={css`
						margin-top: 4px;
					`}
					size="md"
					type="primary"
				>
					{content.description}
				</Typography>
				<Spacer />
				{content.industries.map((industry: IndustryContent, index: number) => {
					return (
						<React.Fragment key={index}>
							<MobileIndustry>
								<MobileIndustryLeft>
									<Typography
										variant="h4"
										type="primary"
										weight="bold"
										css={css`
											margin-top: 0px;
											margin-bottom: 10px;
										`}
									>
										{industry.title}
									</Typography>
									<Typography variant="div" size="md" type="primary">
										{industry.description}
									</Typography>
								</MobileIndustryLeft>
								<MobileIndustryRight
									css={css`
										background-image: url(${industry.bannerImageUrl});
									`}
								>
									<MobileIndustryActionButton
										css={css`
											background-color: ${theme.colors.primary.primaryBrand};
										`}
										onClick={() => {
											setMobileIndustry(industry);
											setShowMobileIndustryDetails(true);
											const body = document?.querySelector("body");
											setBodyStyle(body?.getAttribute("style") ?? "");
											body && body.setAttribute("style", "overflow: hidden;");
										}}
									>
										<Icon
											icon="chevron-right"
											size="xs"
											color={theme.colors.primary.white}
											css={css`
												cursor: pointer;
											`}
										/>
									</MobileIndustryActionButton>
								</MobileIndustryRight>
							</MobileIndustry>
							{index === content.industries.length - 1 ? (
								<>
									<Spacer />
									<Spacer />
									<Spacer />
									<Spacer />
								</>
							) : (
								<MobileDivider
									css={css`
										margin-bottom: 0px;
										margin-top: 0px;
									`}
								/>
							)}
						</React.Fragment>
					);
				})}
				<MobileOverlay
					css={css`
						background-color: ${theme.colors.secondary.background};
						left: ${showMobileIndustryDetails ? "0px" : "-100%"};
					`}
				>
					<MobileOverlayHeader
						css={css`
							background-color: ${theme.colors.primary.darkGray};
						`}
					>
						<MobileOverlayHeaderClose
							css={css`
								border-left: 1px solid ${theme.colors.primary.gray};
							`}
							onClick={() => {
								setShowMobileIndustryDetails(false);
								const body = document?.querySelector("body");
								body && body.setAttribute("style", bodyStyle ?? "");
							}}
						>
							<Icon
								icon="close"
								size="xs"
								color={theme.colors.primary.white}
								css={css`
									cursor: pointer;
								`}
							/>
						</MobileOverlayHeaderClose>
					</MobileOverlayHeader>
					<MobileOverlayBody>
						<Typography
							variant="h3"
							css={css`
								margin-top: 0px;
								margin-bottom: 20px;
							`}
							type="secondary"
						>
							{mobileIndustry.title}
						</Typography>
						<Typography variant="div" size="md" type="secondary">
							{mobileIndustry.description}
						</Typography>
						{mobileIndustry.industryComponents.map(
							(component: IndustryComponent, index: number) => {
								return (
									<React.Fragment key={index}>
										<Flex
											css={css`
												height: 24px;
												line-height: 24px;
												margin-top: 30px;
												margin-bottom: 20px;
											`}
											direction="row"
											justifyContent="space-between"
										>
											<FlexItem>
												<Typography
													variant="div"
													css={css`
														height: 24px;
														line-height: 26px;
													`}
													weight="bold"
													color={theme.colors.secondary.header}
													size="lg"
												>
													{component.title}
												</Typography>
											</FlexItem>
											<FlexItem>
												<FauxButtonLink
													onClick={() => {
														if (
															window &&
															(window as any).LiveChatWidget
														) {
															(window as any).LiveChatWidget.call(
																"maximize"
															);
														}
													}}
												>
													<FauxButtonLinkText
														css={css`
															margin-right: 10px;
														`}
													>
														<Typography
															variant="div"
															size="sm"
															weight="bold"
															color={theme.colors.primary.header}
														>
															{component.helpText.join(" ")}
														</Typography>
													</FauxButtonLinkText>
													<FauxButtonLinkIcon
														css={css`
															background-color: ${theme.colors.primary
																.link};
															height: 24px;
															width: 24px;
															line-height: 26px;
														`}
													>
														<Icon
															icon="question-mark"
															color={theme.colors.primary.white}
															size="xs"
														/>
													</FauxButtonLinkIcon>
												</FauxButtonLink>
											</FlexItem>
										</Flex>
										<MobileDivider
											css={css`
												color: ${theme.colors.primary.darkerGray};
											`}
										/>
										{component.componentParts.map(
											(part: IndustryComponentPart, index: number) => {
												return (
													<React.Fragment key={index}>
														<MobilePartSliver
															css={css`
																background-color: ${theme.colors
																	.primary.darkerGray};
																border-radius: 2px 2px 0 0;
															`}
														>
															<Typography
																weight="bold"
																variant="div"
																size="md"
																css={css`
																	line-height: 40px !important;
																`}
																color={theme.colors.primary.white}
															>
																{part.title}
															</Typography>
														</MobilePartSliver>
														{part.pieces.map(
															(
																piece: IndustryComponentPartPiece,
																index: number
															) => {
																return (
																	<MobilePartSliver
																		key={index}
																		onClick={() => {
																			navigate(piece.url);
																		}}
																		css={css`
																			background-color: ${theme
																				.colors.primary
																				.background};
																			margin-bottom: 1px;
																			border-radius: ${index ===
																			part.pieces.length - 1
																				? "2px 2px 0 0"
																				: "0 0 0 0"};
																		`}
																	>
																		<Typography
																			variant="div"
																			size="md"
																			css={css`
																				line-height: 40px !important;
																			`}
																			color={
																				theme.colors.primary
																					.header
																			}
																		>
																			{piece.title}
																		</Typography>
																		<MobilePartSliverIcon>
																			<Icon
																				icon={piece.icon}
																				size="sm"
																				color={
																					theme.colors
																						.primary
																						.gray
																				}
																			/>
																		</MobilePartSliverIcon>
																	</MobilePartSliver>
																);
															}
														)}
													</React.Fragment>
												);
											}
										)}
									</React.Fragment>
								);
							}
						)}
					</MobileOverlayBody>
				</MobileOverlay>
			</Media>
		</>
	);
};

export default IndustriesWeServe;

const MobileOverlay = styled.div`
	position: fixed;
	top: 0;
	display: block;
	height: 100%;
	width: 100%;
	z-index: 99;
	left: -100%;
	transition: left 0.5s ease-in-out;
`;

const MobileOverlayHeader = styled.div`
	display: block;
	width: 100%;
	height: 50px;
	position: relative;
`;

const MobileOverlayHeaderClose = styled.div`
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	box-sizing: border-box;
	width: 51px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	cursor: pointer;
`;

const MobileOverlayBody = styled.div`
	display: block;
	width: 100%;
	height: calc(100% - 50px);
	overflow-y: auto;
	overflow-x: hidden;
	padding: 20px 20px 100px 20px;
	box-sizing: border-box;
`;

const MobilePartSliver = styled.div`
	display: block;
	width: 100%;
	height: 40px;
	line-height: 40px;
	padding-left: 20px;
	padding-right: 40px;
	box-sizing: border-box;
	position: relative;
`;

const MobilePartSliverIcon = styled.div`
	display: block;
	position: absolute;
	right: 20px;
	height: 20px;
	top: 10px;
	text-align: center;
	line-height: 20px;
	img {
		width: 20px;
		height: auto;
		display: inline-block;
	}
`;
